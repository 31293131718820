import React, { Component } from 'react';
import styles from './NextPageSwitch.module.css';
import common from './common.module.css';

import {
  Link
} from "react-router-dom";

class NextPageSwitch extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const props = this.props;

    return [
      <div className={styles.content}>
        <span className={styles.title}>Weiter geht die Reise!</span>
        <span className={styles.subTitle}>{props.subTitle}</span>
        <p>
          <Link to={props.to} onClick={props.onClick} className={styles.button} style={{ float: "left" }}>
            &rarr; Weiter
          </Link>
        </p>
      </div>
    ];
  }
}

export default NextPageSwitch;
