import React, { Component } from 'react';
import styles from './StatementsPage.module.css';
import common from './common.module.css';

import BusInterior from './BusInterior';
import Statement from './Statement';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";


class StatementsPage extends Component {
  render() {
    const props = this.props;

    return (
      <div className={common.main} id={"section-" + props.name}>
        <div className={common.container}>
          <div className={styles.content}>
            <div className={styles.statementContentTop}>
              {props.children[0]}
            </div>
            <div className={styles.busContainer}>
              <div className={styles.busBackground}></div>
              <div className={styles.credits}>
              <a target="_blank" href="https://www.freepik.com/free-photos-vectors/background">Background vector created by katemangostar - www.freepik.com</a>
              <a target="_blank" href="https://www.freepik.com/free-photos-vectors/business">Business vector created by gstudioimagen - www.freepik.com</a>
              </div>
              <BusInterior key="isp1" busType={props.busType} statements={props.statements}>
                {
                  props.statements.map((statement) =>
                    <Statement person={statement} />
                  )
                }
              </BusInterior>
            </div>
            <MobileView>
              <p className={styles.infoText}>
                Drehe dein Gerät in den <u>Landschafts-Modus</u>, um den Bus optimal zu sehen
              </p>
                
            </MobileView>
            <div className={styles.statementContentBottom}>
              {props.children[1]}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StatementsPage;
