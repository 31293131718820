
import React, { Component } from 'react';
import styles from './Tabs.module.css';


class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = { tabNr: props.hasAudio ? 0 : 1 };
  }

  setTabNr(newValue) {
    this.setState({ tabNr: newValue });
  }

  render() {
    const hasAudio = this.props.hasAudio;
    const hasText = this.props.hasText;
    const leftPos = this.state.tabNr === 0 && hasAudio ? 0 : 50;

    return (
      <div className={styles.tabContainer}>
        <div className={styles.tabHeader}>
          <div className={styles.tabControls}>
            <button onClick={(e) => this.setTabNr(0)} disabled={!hasAudio} className={styles.tabButton}>Audio</button>
            <button onClick={(e) => this.setTabNr(1)} disabled={!hasText} className={styles.tabButton}>Text</button>
          </div>
          <div className={styles.tabIndicator} style={{ left: leftPos + "%" }} />
        </div>

        <div className={styles.tabContent}>
          {
            this.state.tabNr === 0 ?
              this.props.children[0] :
              this.props.children[1]
          }
        </div>
      </div>
    );
  }
}


export default Tabs;