

import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import NextPageSwitch from './NextPageSwitch'


import styles from './IntroductionPage.module.css';
import common from './common.module.css';

class IntroductionPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const props = this.props;
    const body = props.intro.map((paragraph) =>
      <p>{paragraph}</p>
    );

    return [
      <div className={common.main}>
        <div className={common.container}>
          <article className={styles.content}>
            <span className={common.guideContent}>
              <div className={common.guideImage}></div>
              <article className={common.guideText}>
                <h1>Willkommen bei der Station: {props.title}</h1>
                {body}
                <NextPageSwitch to={"/article-" + props.name} onClick={(e) => props.handleSwitchPage(e)} />
              </article>
            </span>
          </article>
        </div>
      </div>
    ];
  }
}

export default IntroductionPage;
