import React, { Component } from 'react';
import styles from './StationElement.module.css';

import {
  Link
} from "react-router-dom";


import InfoIcon from './images/flaticon/svg/002-info.svg';
import PeopleIcon from './images/flaticon/svg/003-multiple-users-silhouette.svg';


export class StationElement extends Component {
  render() {
    const props = this.props;
    const color = props.color;

    return (
      <li ref={props.fref} className={styles.stationItem} id={props.key}>
        <span className={styles.stationElementText}> {props.title} </span>
        <Link to={props.to} onClick={props.handleSwitchPage} className={styles.stationElementIcon} style={{ backgroundColor: color, backgroundImage: "url(" + props.icon + ")" }}></Link>
      </li>
    );
  }
}


export class SubStationElement extends Component {
  render() {
    const props = this.props;
    const size = 60;
    const color = props.color;

    return (
      <li ref={props.fref} className={styles.stationItem} id={props.key}>
        <Link to={props.to} onClick={props.handleSwitchPage} className={styles.stationElementIcon} style={{ backgroundImage: "url(" + props.icon + ")", backgroundColor: color, width: size + "%", height: size + "%" }}></Link>
      </li>
    );
  }
}

export class Station extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const props = this.props;
    const station = props.station;
    const refs = props.refs;
    const colors = props.colors;

    return [
      <SubStationElement fref={refs[0]} color={colors[0]} key={station.name + "-01"} to={"/introduction-" + station.name} name={station.name} icon={InfoIcon} title={station.title} handleSwitchPage={(e) => props.handleSwitchPage(e, 0)} />,
      <StationElement fref={refs[1]} color={colors[1]} key={station.name + "-02"} to={"/article-" + station.name} name={station.name} icon={station.icon} title={station.title} handleSwitchPage={(e) => props.handleSwitchPage(e, 1)} />,
      <SubStationElement fref={refs[2]} color={colors[2]} key={station.name + "-03"} to={"/opinion-" + station.name} name={station.name} icon={PeopleIcon} title={station.title} handleSwitchPage={(e) => props.handleSwitchPage(e, 2)} />
    ];
  }
}

export default Station;

