
import React, { Component } from 'react';
import styles from './BusInterior.module.css';
import SpeechBubble from './images/custom/speech_bubble-object.svg';

import ManObject from './images/custom/man-object2.svg';
import WomanObject from './images/custom/woman-object2.svg';

class BusInterior extends Component {
  constructor(props) {
    super(props);
    this.state = { personIndex: -1 };
    this.personObjects = [];

    this.escFunction = this.escFunction.bind(this);

    const busType = this.props.busType;

    /* Units: vw */
    var positions =
      busType === "classic" ?
        { 'male': [-3, 12, 28, 35, 50, 57], 'female': [4, 20.5, 42, 65.5] } :
        { 'male': [-3, 12, 29, 37, 52, 62], 'female': [4.5, 21.5, 44.5, 69] };

    const permutation = { 'male': [3, 5, 1, 2, 4, 0], 'female': [0, 1, 2, 3] };

    /* x-scale (flipped or not) */
    var orientations = { 'male': [-1, -1, -1, -1, 1, 1], 'female': [1, 1, 1, 1] };
    var counter = { 'male': 0, 'female': 0 };

    for (var idx = 0; idx < this.props.statements.length; idx++) {
      const sex = this.props.statements[idx].sex;
      const path = sex === "male" ? ManObject : WomanObject;

      const personIdx = permutation[sex][counter[sex]];
      const posX = positions[sex][personIdx];
      const scale = orientations[sex][personIdx];

      // !! Copy needed !!
      const id = idx;

      const humanClass = sex === 'male' ? styles.maleHumanObject : styles.femaleHumanObject;

      var obj = [
        <img src={path} className={humanClass} style={{ left: posX + "vw", transform: "scale(" + scale + ", 1)" }}></img>,
        <div onClick={this.state.personIndex != idx ? (e) => this.handlePersonClicked(id) : null} className={styles.speechBubbleObject} style={{ left: (posX + 4) + "vw", top: "0vw" }}>
          <span>...</span>
        </div>
      ];
      counter[sex]++;
      this.personObjects.push(obj);
    }
  }


  escFunction(event) {
    if (event.keyCode === 27) {
      this.reseta();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  handlePersonClicked(idx) {
    this.setState({ personIndex: idx });
  }

  reseta() {
    this.setState({ personIndex: -1 });
  }

  render() {
    var showModal = false;
    var modal = null;

    const busType = this.props.busType;
    const busInteriorClass = (busType === "classic") ? styles.classicBusInteriorObject : styles.modernBusInteriorObject;

    for (var idx = 0; idx < this.props.statements.length; idx++) {
      if (idx == this.state.personIndex) {
        showModal = true;
        modal = (
          <div className={styles.modalBox}>
            <span onClick={(e) => this.reseta()} className={styles.close}>&times;</span>
            {this.props.children[idx]}
          </div>
        );
      }
    }

    return (
      <div>
        {
          showModal ? modal : null
        }
        <div className={busInteriorClass}>
          {this.personObjects}
        </div>
      </div>
    );
  }
}

export default BusInterior;
