
import React, { Component } from 'react';
import { withRouter } from 'react-router'


class AppContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      console.log(this.props.location.pathname);
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }
  render() {

    return (
      <div>
        {!this.props.miscPageNames.includes(this.props.location.pathname) ? this.props.children[0] : ''}
        {this.props.children.slice(1)}
      </div>
    );
  }
}

export default withRouter(AppContainer);