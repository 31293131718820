
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styles from './BusChoice.module.css';
import common from './common.module.css';

import modernBus from './images/custom/modern-bus.svg';
import classicBus from './images/custom/classic-bus.svg';

import StatementsPage from './StatementsPage';
import NextPageSwitch from './NextPageSwitch'

import SwipeableViews from 'react-swipeable-views';


import { withRouter } from "react-router-dom";


import {
  Link
} from "react-router-dom";


import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

import App from './App';
import Footer from './Footer';


class BusChoice extends Component {
  constructor(props) {
    super(props);
    this.state = {animate: false, animationIteration: 0};
  }

  busClicked(busType) {
    let history = this.props.history;
    const targetPath = this.props.routeMap[busType];
    const iter = this.state.animationIteration;

    if (this.props.disableAnimation) {
        history.push(targetPath);
    }
    else if (iter == 0) {
        this.setState({animationIteration: 1});
        setTimeout(() => {
            this.setState({animationIteration: 2});
        }, 1000);
        setTimeout(function(){ 
            history.push(targetPath);
        }, 2500);
    }
  }

  render() {
    const iter = this.state.animationIteration;
    return (
      <div style={{ margin: "0" }}>
        <BrowserView>
            <div className={styles.choiceContainer}>
              <article className={styles.content}>
                <div className={iter >= 1 ? styles.boxTransparentLeave : styles.boxTransparent}>
                  <h1 className={common.headLine}>
                    {
                    this.props.headline
                    }
                            </h1>
                  <div className={styles.guideContent}>
                    <div className={styles.guideImage}></div>
                    {this.props.children}
                  </div>
                </div>

                <div className={iter === 2 ? styles.busChoiceLeave : styles.busChoice}>
                  <div onClick={(e) => this.busClicked('classic')} className={styles.busButton}>
                    <span> Bus mit Busfahrer </span>
                    <img src={classicBus}></img>
                  </div>
                  <div onClick={(e) => this.busClicked('modern')} className={styles.busButton}>
                    <span> Autonom fahrender Bus </span>
                    <img src={modernBus} style={{ width: "30vw" }} ></img>
                  </div>
                </div>
                <div className={styles.credits}>
                  <a href="https://www.freepik.com/free-photos-vectors/background">Background vector created by katemangostar - www.freepik.com</a>
                </div>
              </article>
            </div>
        </BrowserView>
        <MobileView>
        <div className={styles.choiceContainer}>
              <article className={styles.content}>
                <div className={iter >= 1 ? styles.boxTransparentLeaveM : styles.boxTransparentM}>
                  <h1 className={common.headLine}>
                    {
                    this.props.headline
                    }
                            </h1>
                  <div className={styles.guideContent}>
                    <div className={styles.guideImage}></div>
                    {this.props.children}
                  </div>
                </div>

                <SwipeableViews index={1} enableMouseEvents className={styles.busChoiceM} containerStyle={{height: "100%"}}>
                  <div onClick={(e) => this.busClicked('classic')} className={iter === 2 ? styles.busButtonLeaveM : styles.busButtonM}>
                    <span style={{color: "black", backgroundColor: "rgba(230, 189, 65, 0.9)"}}> Bus mit Busfahrer </span>
                    <img src={classicBus}></img>
                  </div>
                  <div className={styles.busChoiceInfoM}>
                    <div>
                      <span> &larr;Mit Busfahrer </span>
                      <span>Autonom &rarr; </span>
                    </div>
                  </div>
                  <div onClick={(e) => this.busClicked('modern')} className={iter === 2 ? styles.busButtonLeaveM : styles.busButtonM}>
                    <span style={{color: "black", backgroundColor: "rgba(213, 213, 213, 0.9)"}}> Autonom fahrender Bus </span>
                    <img src={modernBus} ></img>
                  </div>
                </SwipeableViews>

                <div className={styles.credits}>
                  <a href="https://www.freepik.com/free-photos-vectors/background">Background vector created by katemangostar - www.freepik.com</a>
                </div>
              </article>
            </div>
        </MobileView>
      </div>
      );
  }
}

export default withRouter(BusChoice);
