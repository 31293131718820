import React, { Component } from 'react';
import styles from './SingleTopicPage.module.css';

import NextPageSwitch from './NextPageSwitch'


class SingleTopicPage extends Component {
  render() {
    const props = this.props;

    return (
      <div className={styles.main} id={"section-" + props.name}>
        <div className={styles.container}>
          <div className={styles.content}>
            <aside style={{ color: "#151951" }}>
              {
                props.article.map(section =>
                  <p>{section.aside}</p>
                )
              }
            </aside>
            <article id={"article-" + props.name} className={styles.articleBody}>
              <StartSection title={props.article[0].headline} paragraphs={props.article[0].paragraphs} />
              {
                props.article.slice(1).map(section =>
                  <Section title={section.headline} paragraphs={section.paragraphs} />
                )
              }
              <NextPageSwitch to={"/opinion-" + props.name} onClick={(e) => props.handleSwitchPage(e)} />
            </article>
          </div>

          <br /><hr className={styles.hrElegant} />
          <ReferenceList maxItems={3}>
            {
              props.references.map(ref =>
                <ReferenceItem title={ref.title} authors={ref.authors} url={ref.url} />
              )
            }
          </ReferenceList>
        </div>
      </div>
    );
  }
}


export class StartSection extends Component {

  constructor(props) {
    super(props);
    this.paragraphs =
      props.paragraphs.map(paragraph =>
        <p>
          {paragraph}
        </p>
      )
  }

  render() {
    return (
      <div>
        <h1>{this.props.title}</h1>
        {
          this.paragraphs
        }
      </div>
    );
  }
}


export class Section extends Component {

  constructor(props) {
    super(props);
    this.paragraphs =
      props.paragraphs.map(paragraph =>
        <p>
          {paragraph}
        </p>
      )
  }

  render() {
    return (
      <div>
        <h2>{this.props.title}</h2>
        {
          this.paragraphs
        }
      </div>
    );
  }
}


export class ReferenceItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const url = this.props.url;
    const title = this.props.title;
    const authors = this.props.authors;
    return (
      <li>
        <span>
          {authors}
        </span>
        <br />
        <span>
          {title}
        </span>
        <span>
        </span>
        <p>
          <b>Link dazu: </b>
          <a target="_blank" href={url}>{url}</a>
        </p>
      </li>
    );
  }
}


export class ReferenceList extends Component {
  constructor(props) {
    super(props);
    this.state = { showMore: false };
  }

  render() {
    const maxItems = this.props.maxItems;
    const itemCount = this.props.children.length;
    const showMore = this.state.showMore;

    const indicator = (
      <span className={styles.moreItemsIndicator} onClick={(e) => this.setState({ showMore: !showMore })}>
        <u>{showMore === true ? "Zeige weniger Referenzen" : "Zeige alle Referenzen"}</u>
      </span>
    );

    const tmp = (
      <div className={styles.referenceListContainer}>
        <h2>Zum Weiterlesen:</h2>
        <ul className={styles.referenceList}>
          {this.props.children.slice(0, showMore ? itemCount : maxItems)}
        </ul>
        {
          itemCount > maxItems ? indicator : ""
        }
      </div>
    );

    return tmp;
  }
}

export default SingleTopicPage;

