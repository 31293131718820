
import React, { Component } from 'react';
import styles from './Statement.module.css';
import Tabs from './Tabs';
//import AudioPlayer from './AudioPlayer';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';



class Statement extends Component {
  constructor(props) {
    super(props);

    const person = props.person;

    this.hasText = Array.isArray(person.texts) && person.texts.length;
    this.hasAudio = person.audio != null;
    this.state = { value: this.hasAudio ? 0 : 1 };

    if (this.hasText) {
      this.texts = person.texts.map((text) =>
        <p className={styles.textQuote}>
          {text}
        </p>
      );
    }
  }

  handleChange(newValue) {
    this.setState({ value: newValue });

  }

  render() {
    const person = this.props.person;
    const male = person.sex === "male";
    const value = this.state.value;

    //const headline = person.name + (person.profession !== "" ? " - " + person.profession : "");
    // TODO Replace by real name if ready to deploy
    const headline = person.name;

    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.profile}>
            <img src={person.photo}></img>
            <span>{headline}</span>
          </div>

          <div className={styles.tabs}>
            <Tabs hasAudio={this.hasAudio} hasText={this.hasText}>
              <AudioPlayer style={{fontFamily: "Open Sans", backgroundColor: "#f2f2f2"}} src={person.audio} showLoopControl={false} showJumpControls={false} showVolumeControl={false}  />
              <article className={styles.interviewText}>
                {this.texts}
              </article>
            </Tabs>
          </div>
        </div>

      </div>
    );
  }
}

/*
              <strong>Dieses Interview als Text</strong>

            <audio controls>
            <source src={person.audio} type="audio/mpeg"/>
          </audio>
*/

export default Statement;