
import React, { Component } from 'react';
import common from './common.module.css';
import footer from './Footer.module.css';

import {
  Link
} from "react-router-dom";

import { withRouter } from "react-router-dom";

class Footer extends Component {
  reload(e) {
    // TODO: A bit hacky. It would be nicer to launch 'BusChoiceApp' instead.
    e.preventDefault();
    this.props.history.push("/");
    window.location.reload(false);
  }

  render() {
    return (
      <footer>
        <div className={footer.container}>
          <div className={footer.links}>
            <div className={footer.column}>
              <ul className={footer.list}>
                <li className={footer.link}>
                  <span>App</span>
                </li>
                <li className={footer.link}>
                  <Link onClick={(e) => this.reload(e)} to="/">Home</Link>
                </li>
                <li className={footer.link}>
                  <Link onClick={(e) => this.reload(e)} to="/">Stationen</Link>
                </li>
              </ul>
            </div>
            <div className={footer.column}>
              <ul className={footer.list}>
                <li className={footer.link}>
                  <span>Rechtliches</span>
                </li>
                <li className={footer.link}>
                  <Link to="/privacy">Datenschutz</Link>
                </li>
                <li className={footer.link}>
                  <Link to="/license">Lizenzen</Link>
                </li>
                <li className={footer.link}>
                  <Link to="/imprint">Impressum</Link>
                </li>
              </ul>
            </div>
            <div className={footer.column}>
              <ul className={footer.list}>
                <li className={footer.link}>
                  <span>Über unser Projekt</span>
                </li>
                <li className={footer.link}>
                  <Link to="/about">Über uns</Link>
                </li>
                <li className={footer.link}>
                  <Link to="/dialogue">Bürgerdialog</Link>
                </li>
              </ul>
            </div>
          </div>
          <hr className={common.hrShadow} />

          <div className={footer.creditList}>
            <span>
              Icons made by <a target="_blank" href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> <br /> from <a target="_blank" href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
            </span>

            <span>
              Icons made by <a target="_blank" href="https://www.flaticon.com/authors/chanut" title="Chanut">Chanut</a> <br /> from <a target="_blank" href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
            </span>

            <span>
              Teaser images <br /> from <a target="_blank" href="https://www.unsplash.com/" title="Unsplash">www.unsplash.com</a>
            </span>
          </div>
          <hr className={common.hrShadow} />

          <div className={footer.license}>
            © 2019-2020 Das Team der Hochschule der Medien und Uni Stuttgart <br />
            in Kooperation mit <a target="_blank" href="https://www.riffreporter.de/">https://www.riffreporter.de/</a>
                <p>
              Made with <span style={{ color: "#e25555" }}>&#9829;</span>  in Stuttgart
                </p>
          </div>
        </div>
      </footer>
    );
  }
}

/*

                <li className={footer.link}>
                  <Link to="/credits">Danksagungen</Link>
                </li>
*/

export default withRouter(Footer);

/*


          <div className={footer.container}>
          <div className={footer.column}>
            <h3>Lorem ipsum</h3>
            <ul>
              <li className={footer.link}>
                <a href="#">Lorem ipsum</a>
              </li>
              <li className={footer.link}>
                <a href="#">Lorem ipsum</a>
              </li>
              <li className={footer.link}>
                <a href="#">Lorem ipsum</a>
              </li>
            </ul>
          </div>
          <div className={footer.column}>
            <h3>Lorem ipsum</h3>
            <ul>
              <li className={footer.link}>
                <a href="#">Lorem ipsum</a>
              </li>
              <li className={footer.link}>
                <a href="#">Lorem ipsum</a>
              </li>
              <li className={footer.link}>
                <a href="#">Lorem ipsum</a>
              </li>
            </ul>
          </div>
        </div>



*/