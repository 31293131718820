
import React, { Component } from 'react';
import common from './common.module.css';
import styles from './MiscPages.module.css';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import diaImage01 from './images/buergerdialog/01.JPG';
import diaImage02 from './images/buergerdialog/02.JPG';
import diaImage03 from './images/buergerdialog/03.JPG';
import diaImage04 from './images/buergerdialog/04.JPG';


import p01 from './images/p01.jpg';
import p02 from './images/p02.jpg';
import p03 from './images/p03.jpg';

import profileCeline from './images/profil-celine.jpg';
import profilJana from './images/profil-jana.jpg';
import profilMaurice from './images/profil-maurice.JPG';
import profilPatrick from './images/profil-patrick.jpg';



export const MISC_PAGES = {
  "/license": (
    <div className={common.main}>
    <div className={common.container}>
      <div className={styles.content}>
        <h1>Lizenzen</h1>

        <div className={styles.text}>
          Diese Webapplikation enthält Grafiken und Fonts aus externen Quellen. 
          Die Verarbeitung und Weitergabe dieser Inhalte erfolgt im Rahmen der jeweiligen Lizenzrechte.
          Entsprechende Verweise zu den Quellen sind unten mit Links aufgeführt.
          Die lizenzrechliche Bedinungen sind von den entsprechenden Herausgebern zu entnehmen.
        </div>

        <h2>Bilder und Grafiken</h2>
        <div className={styles.links}>
        <ul>
          <li className={styles.linkElement}>
            <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
            <ul>
              <li className={styles.linkElement}>
                <a href="https://www.flaticon.com/free-icon/wrench_593">https://www.flaticon.com/free-icon/wrench_593</a>
              </li>

              <li className={styles.linkElement}>
                <a href="https://www.flaticon.com/free-icon/car-compact_31126">https://www.flaticon.com/free-icon/car-compact_31126</a>
              </li>

              <li className={styles.linkElement}>
                <a href="https://www.flaticon.com/free-icon/multiple-users-silhouette_33308">https://www.flaticon.com/free-icon/multiple-users-silhouette_33308</a>
              </li>

              <li className={styles.linkElement}>
                <a href="https://www.flaticon.com/free-icon/caduceus_63854">https://www.flaticon.com/free-icon/caduceus_63854</a>
              </li>
            </ul>
          </li>

          <li className={styles.linkElement}>
            <div>Icons made by <a href="https://www.flaticon.com/authors/chanut" title="Chanut">Chanut</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
            <ul>
              <li className={styles.linkElement}>
                <a href="https://www.flaticon.com/free-icon/info_151776">https://www.flaticon.com/free-icon/info_151776</a>
              </li>
            </ul>
          </li>

          <li>
            Station teaser images from <a href="https://unsplash.com">https://unsplash.com</a>:
          <ul>
              <li className={styles.linkElement}>
                <a href="https://unsplash.com/photos/y5hQCIn1c6o">https://unsplash.com/photos/y5hQCIn1c6o</a> by <a href="https://unsplash.com/@gpiron ">Piron Guillaume</a>
              </li>
              <li className={styles.linkElement}>
                <a href="https://unsplash.com/photos/NqOInJ-ttqM">https://unsplash.com/photos/NqOInJ-ttqM</a> by <a href="https://unsplash.com/@marcojodoin ">Marc-Olivier Jodoin</a>
              </li>
              <li className={styles.linkElement}>
                <a href="https://unsplash.com/photos/wnOJ83k8r4w">https://unsplash.com/photos/wnOJ83k8r4w</a> by <a href="https://unsplash.com/@robin_sommer ">Robin Sommer</a>
              </li>
            </ul>
          </li>


          <li className={styles.linkElement}>
            Urban background scene designed by <a href="http://www.freepik.com">Katemangostar / Freepik</a>
          </li>

          <li className={styles.linkElement}>
          Business vector created by <a href="https://www.freepik.com/free-photos-vectors/business">gstudioimagen - www.freepik.com</a>
          </li>

          <li className={styles.linkElement}>
            Avatar made with <a href="https://avatarmaker.com/">https://avatarmaker.com/</a>
          </li>

        </ul>

        <h2>Fonts</h2>

        <ul>
          <li className={styles.linkElement}>
            https://fonts.google.com/specimen/Roboto
          </li>
          <li className={styles.linkElement}>
            https://fonts.google.com/specimen/Quicksand
          </li>
          <li className={styles.linkElement}>
            https://fonts.google.com/specimen/Open+Sans
          </li>
        </ul>          
        </div>

      </div>
    </div>
  </div>


  ),
  "/contact": (
    <div className={common.main}>
      <div className={common.container}>
        <div className={common.content}>
          <h1>Kontakt</h1>
        </div>
      </div>
    </div>
  ),
  "/imprint": (
    <div className={common.main}>
      <div className={common.container}>
        <div className={styles.content}>
          <div className={styles.text}>
            <h1>Impressum</h1><p>Angaben gemäß § 5 TMG</p>
            <p>Maurice Koch<br />
              Königsberger Straße 71<br />
              71139 Ehningen <br />
              <p>In Kooperation mit <a target="_blank" href="https://www.riffreporter.de/">https://www.riffreporter.de/</a> </p>
            </p><p><strong>Kontakt:</strong> <br />
              Email: admin@kochme.de<br /></p>

            <p><strong>Haftungsausschluss: </strong><br /><br /><strong>Haftung für Inhalte</strong><br /><br />
              Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.<br /><br /><strong>Haftung für Links</strong><br /><br />
              Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.<br /><br /><strong>Urheberrecht</strong><br /><br />
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.<br /><br /><strong>Datenschutz</strong><br /><br />
              Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. <br />
              Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. <br />
              Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.<br />
            </p><br />
            Impressum vom <a href="https://www.impressum-generator.de">Impressum Generator</a> der <a href="https://www.kanzlei-hasselbach.de/">Kanzlei Hasselbach, Rechtsanwälte für Arbeitsrecht und Familienrecht</a>
          </div>

        </div>
      </div>
    </div>
  ),
  "/privacy": (
    <div className={common.main}>
      <div className={common.container}>
        <div className={styles.content}>

        <h2>Datenschutzerklärung</h2>
        <div className={styles.text}>

<p>Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen der Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet.
</p>
<p>Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.</p>
<p>Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend über die von uns zu Optimierungszwecken sowie zur Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten in wiederum eigener Verantwortung verarbeiten.</p>
<p>Unsere Datenschutzerklärung ist wie folgt gegliedert:</p>
<p>I. Informationen über uns als Verantwortliche<br />II. Rechte der Nutzer und Betroffenen<br />III. Informationen zur Datenverarbeitung</p>
<h3>I. Informationen über uns als Verantwortliche</h3>
<p>Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:</p>
<p><span>Maurice Koch<br />Königsberger Straße 71<br />71139 Ehningen<br />Deutschland</span></p>
<p><span>E-Mail: admin@kochme.de</span></p>
<h3>II. Rechte der Nutzer und Betroffenen</h3>
<p>
  Einige der von uns verarbeiteten Daten betreffen insbesondere Ton-und Fotoaufnahmen (nachfolgend "Aufnahmen" genannt) die im Rahmen des Bürgerdialogs zum Thema "Chancen und Risiken der Künstlichen Intelligenz" am 03. Dezember 2019 in der Stadtbibliothek Stuttgart entstanden sind.
  Die Aufnahmen wurden mit vorheriger schriftlicher Einwilligung der betroffenen Personen angefertigt und werden im Rahmen dieses Webauftritts in veränderter Form dargestellt. 
  Betroffene haben jederzeit die Möglichkeit von Ihren Rechten, gemäß der DSGVO gebrauch zu machen.
  Dies bedeutet insbesondere das Recht zur Widerrufung ihrer schriftlichen Einwilligung und somit auf die unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO).
  Wenden Sie sich bitte in diesen Fällen schriftlich per Email an uns (E-Mail: admin@kochme.de).
</p>
<p>  
  Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer und Betroffenen das Recht
</p>
<ul>
<li>auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die verarbeiteten Daten, auf weitere Informationen über die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15 DSGVO);</li>
<li>auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl. auch Art. 16 DSGVO);</li>
<li>auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder, alternativ, soweit eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist, auf Einschränkung der Verarbeitung nach Maßgabe von Art. 18 DSGVO;</li>
<li>auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf Übermittlung dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);</li>
<li>auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie betreffenden Daten durch den Anbieter unter Verstoß gegen datenschutzrechtliche Bestimmungen verarbeitet werden (vgl. auch Art. 77 DSGVO).</li>
</ul>
<p>Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten durch den Anbieter offengelegt worden sind, über jedwede Berichtigung oder Löschung von Daten oder die Einschränkung der Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden ist. Unbeschadet dessen hat der Nutzer ein Recht auf Auskunft über diese Empfänger.</p>
<p><strong>Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch gegen die künftige Verarbeitung der sie betreffenden Daten, sofern die Daten durch den Anbieter nach Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere ist ein Widerspruch gegen die Datenverarbeitung zum Zwecke der Direktwerbung statthaft.</strong></p>
<h3>III. Informationen zur Datenverarbeitung</h3>
<p>Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt, der Löschung der Daten keine gesetzlichen Aufbewahrungspflichten entgegenstehen und nachfolgend keine anderslautenden Angaben zu einzelnen Verarbeitungsverfahren gemacht werden.</p>

<p>
<a target="_blank" href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html">Muster-Datenschutzerklärung</a> der <a target="_blank" href="https://www.ratgeberrecht.eu/datenschutz/datenschutzerklaerung-generator-dsgvo.html">Anwaltskanzlei Weiß &amp; Partner</a></p>

        </div>

        </div>
      </div>
    </div>
  ),
  "/about": (
    <div className={common.main}>
      <div className={common.container}>
        <div className={styles.content}>
        <h1>ÜBER UNS</h1>

          <div className={styles.text}>
            <p>
              Dieses Projekt ist im Rahmen eines Seminars zwischen der Hochschule der Medien in Stuttgart
              sowie der Uni Stuttgart entstanden. 
              Leitthema des Projekts war: Wir und KI.
              In jeweils vier Gruppen entstanden unterschiedliche Projekte mit dem Ziel, der Gesellschaft das Thema
              der Künstlichen Intelligenz verständlich zu erläutern.
              Die Hauptaufgabe bestand dabei in der Erstellung von journalistischen Beiträgen.
              Grundlage hierfür war der Bürgerdialog - eine offene Veranstaltung in Form einer Unterhausdebatte, die in der Stadtbibliothek Stuttgart stattfand.
            </p>
            <p>
              Ein weiteres Ziel der Veranstaltung bestand in der Zusammenarbeit zwischen Studenten aus unterschiedlichen Fachrichtungen.
              Das voneiander Lernen war dabei ebenso wichtig wie die Fertigstellung eines erfolgreichen Projekts.
            </p>
          </div>

          <div className={styles.profileWrapper}>
            <div className={styles.profilesHeadline}>
              Das Team
            </div>

            <div className={styles.profileContainer}>
              <div className={styles.profile}>
                <img src={profileCeline} className={styles.profileImage}></img>
                  <span className={styles.profileName}>
                    Celine Bischoff
                  </span>
                  <p>
                  Studiert aktuell Crossmedia-Redaktion/Public Relations an der Hochschule der Medien (HdM) in Stuttgart.
                  </p>
              </div>

              <div className={styles.profile}>
              <img src={profilPatrick} className={styles.profileImage}></img>
                  <span className={styles.profileName}>Patrick Denk</span>
                  <p>
                  Studiert aktuell Planung und Partizipation an der Uni Stuttgart.
                  </p>
              </div>

              <div className={styles.profile}>
                <img src={profilJana} className={styles.profileImage}></img>
                  <span className={styles.profileName}>Jana Mack</span>
                  <p>
                  Studiert aktuell Crossmedia-Redaktion/Public Relations an der Hochschule der Medien (HdM) in Stuttgart.
                  </p>
              </div>

              <div className={styles.profile}>
              <img src={profilMaurice} className={styles.profileImage}></img>
                  <span className={styles.profileName}>Maurice Koch</span>
                  <p>
                  Studiert aktuell Informatik an der Uni Stuttgart.
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  ),
  "/dialogue": (
    <div className={common.main}>
      <div className={common.container}>
          <div className={styles.content}>

            <h1 className={styles.headline}>FÜR ALLES IST GESORGT? - DIE ZUKUNFT DER KI</h1>
            <span className={styles.description}>
              Bürgerdialog im Format einer Unterhausdebatte
            </span>
              <Carousel autoPlay infiniteLoop showThumbs={false} className={styles.carousel}>
                <div>
                  <img src={diaImage01} />
                </div>
                <div>
                  <img src={diaImage02} />
                </div>
                <div>
                  <img src={diaImage03} />
                </div>
                <div>
                  <img src={diaImage04} />
                </div>
              </Carousel>
              <article className={styles.text}>
                <h2>Der Bürgerdialog</h2>
                <p>
                  Der Bürgerdialog fand am 03. Dezember in der <b>Stuttgarter Stadtbibliothek</b> am Mailänder Platz statt.
                    Das Leitthema dieser Debatte war: <i>Für alles ist Gesort? - Die Zukunft der KI</i>.
              Die Fragen des Bürgerdialogs untergliederten sich in die zwei Themenblöcke: Digitale Überwachung sowie Arbeit in der digitalisierten Welt.
                </p>
                <h2>Die Interviews</h2>
                <p>
                  Für uns und zwei weitere Teams galt es die journalistische Grundlage unserer Beiträge zu legen.
                  Es wurden dabei Interviews sowie Bild- und Videoaufnahmen erstellt.
                  Ausgestattet mit Mikrofon und Kamera suchten wir vor der Veranstaltung sowie in der Pause nach geeigneten Interviewpartnern.
                  Glücklicherweise fanden sich einige Freiwillige, die sich bereiterklärten unseren Fragen Rede und Antwort zu stehen.
                  Folgende Fragen stellten wir jedem Interviewpartner:
  
                  <ol>
                    <li className={styles.linkElement}>
                    Bitte vervollständigen Sie den Satz “Ich hätte den Bus mit/ohne Fahrer genommen, weil…”.
                    </li>
                    <li className={styles.linkElement}>
                    Welche Gefühle kommen bei Ihnen auf, wenn Sie sich eine Welt vorstellen, in der autonome Fahrzeuge den Straßenverkehr dominieren?
                    </li>
                    <li className={styles.linkElement}>
                    Durch autonome Autos würde die Tätigkeit des Fahrens selbst wegfallen. Was würde das für Sie bedeuten?
                    </li>
                    <li className={styles.linkElement}>
                    Würden Sie sich mithilfe von roboter-assistierten Operationssystemen (nach heutigem Stand) operieren lassen? Warum (nicht)?
                    </li>
                    <li className={styles.linkElement}>
                    Wem vertrauen Sie mehr: dem menschlichen Arzt oder einem Roboter? Begründen Sie Ihre Antwort.
                    </li>
                    <li className={styles.linkElement}>
                    Könnte eine Künstliche Intelligenz Ihre Arbeit in den nächsten 20 Jahren übernehmen? Warum (nicht)?
                    </li>
                    <li className={styles.linkElement}>
                    Können Sie sich vorstellen, mit einer Künstlichen Intelligenz zusammenzuarbeiten?
                    </li>
                  </ol>
                </p>
              </article>
          </div>
        </div>
    </div>
  ),
  "/credits": (
    <div className={common.main}>
      <div className={common.container}>
        <div className={common.content}>
          <h1>Danksagungen</h1>
          <p>
            Wir danken allen Interviewpartern die sich am Tag des Bürgerdialogs bereit erklärten
            unsere Fragen zu beantworten.
          </p>
          <p>
            Ebenso möchten wir uns bei Professor Mäder sowie dem IZKT der Uni Stuttgart
            bedanken.
          </p>
        </div>
      </div>
    </div>
  )
};
