import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styles from './BusChoiceApp.module.css';
import common from './common.module.css';
import BusChoice from './BusChoice';

import modernBus from './images/custom/modern-bus.svg';
import classicBus from './images/custom/classic-bus.svg';

import StatementsPage from './StatementsPage';
import NextPageSwitch from './NextPageSwitch'

import { withRouter } from "react-router-dom";
import ScrollToTop from 'react-router-scroll-top';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";


import {
  Link
} from "react-router-dom";


import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

import App from './App';
import Footer from './Footer';
import { MISC_PAGES } from './MiscPages';



class BusChoiceApp extends Component {
  constructor(props) {
    super(props);
    this.openIntroductionApp = this.openIntroductionApp.bind(this);
  }

  openIntroductionApp(busType) {
    ReactDOM.render(
      <App stations={this.props.stations} busType={busType} decisions={this.props.decisions} />,
      document.getElementById('root')
    );
  }

  render() {
    const stations = this.props.stations;

    const guideContent = (
      <div className={common.guideContent}>
        <div className={common.guideImage}></div>
        <article className={common.guideText}>
          <h1>Los gehts! Nicht alle waren mit deiner Entscheidung zufrieden,
                  dementsprechend gibt es ein paar Beschwerden.</h1>
          <p>
            <b>Wie es funktioniert:</b> Im Bus (siehe unten) befindet sich deine Reisegruppe.
            Die Aussagen von den einzelnen Personen erscheinen nach einem Klick auf die entsprechenden Sprechblasen.
              Die Sprechblase kann mit einem Klick auf das <strong>&times;</strong> (Kreuz) am oberen rechten Rand wieder geschlossen werden.
        </p>
        </article>
      </div>
    );

    return (
      <Router>
        <ScrollToTop>
        <Switch>
          {
            Object.entries(MISC_PAGES).map(([path, content]) =>
              <Route path={path}>
                {content}
              </Route>
            )
          }

          <Route path="/modern">
            <StatementsPage
              key="msp"
              title={"Ich hätte ..."}
              name={"-"}
              teaser={stations[0].teaser}
              busType="modern"
              statements={this.props.decisions['modern']}
            >
              {guideContent}
              <NextPageSwitch to={"/introduction-" + stations[0].name} onClick={(e) => this.openIntroductionApp('modern')} />
            </StatementsPage>
          </Route>

          <Route path="/classic">
            <StatementsPage
              key="csp"
              title={"Ich hätte ..."}
              name={"-"}
              teaser={stations[0].teaser}
              busType="classic"
              statements={this.props.decisions['classic']}
            >
              {guideContent}
              <NextPageSwitch to={"/introduction-" + stations[0].name} onClick={(e) => this.openIntroductionApp('classic')} />
            </StatementsPage>
          </Route>

          <Route path="/">
            <BusChoice headline={"Eine Reise durch die Welt der Künstlichen Intelligenz"} 
                       routeMap={{classic: '/classic', modern: '/modern'}}>
              <span>
                <MobileView>
                  <p>
                    <b>Hallo, ich bin Karl Ingo, dein Reiseführer. </b>
                    Freut mich, dass du dich mit mir auf eine Reise durch die Welt der Künstlichen Intelligenz begibst.
                    Entscheide für deine Reisegruppe: Möchtet ihr in den selbstfahrenden Bus einsteigen oder in den Bus mit Busfahrer?
                  </p>
                  <p>
                    Swipe um den gewünschten Bus auszuwählen. Mit einem Klick auf den entsprechenden Bus startet dann eure Reise.
                  </p>
                  <p>
                    Viel Spaß auf eurer Tour.
                  </p>
                </MobileView>
                <BrowserView>
                  <p>
                    <b>Hallo, ich bin Karl Ingo, dein Reiseführer. </b>
                    Freut mich, dass du dich mit mir auf eine Reise durch die Welt der Künstlichen Intelligenz begibst.
                    Entscheide für deine Reisegruppe: Möchtet ihr in den selbstfahrenden Bus einsteigen oder in den Bus mit Busfahrer?
                  </p>
                  <p>
                    Starte eure Reise mit einem Klick auf den entsprechenden Bus.
                  </p>
                  <p>
                    Viel Spaß auf eurer Tour.
                  </p>
                </BrowserView>

              </span>
            </BusChoice>
          </Route>
        </Switch>
        <Footer />
        </ScrollToTop>
      </Router>
    );
  }
}

export default BusChoiceApp;
