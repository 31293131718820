import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styles from './EvaluationApp.module.css';
import choiceStyles from './BusChoice.module.css';

import common from './common.module.css';

import BusChoice from './BusChoice';

import modernBus from './images/custom/modern-bus.svg';
import classicBus from './images/custom/classic-bus.svg';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import ScrollToTop from 'react-router-scroll-top'


import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link
} from "react-router-dom";


import Footer from './Footer';
import {MISC_PAGES} from './MiscPages';


const PROGRESS_STYLE_BLUE =
  buildStyles({
    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
    strokeLinecap: 'butt',

    // How long animation takes to go from one percentage to another, in seconds
    pathTransitionDuration: 3.5,

    // Colors
    pathColor: `rgb(0, 102, 255)`,
    textColor: 'rgb(0, 102, 255)',
    trailColor: '#d6d6d6',
    backgroundColor: '#3e98c7',
  });

const PROGRESS_STYLE_RED =
  buildStyles({
    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
    strokeLinecap: 'butt',

    // How long animation takes to go from one percentage to another, in seconds
    pathTransitionDuration: 3.5,

    // Colors
    pathColor: `rgb(204, 0, 0)`,
    textColor: 'rgb(204, 0, 0)',
    trailColor: '#d6d6d6',
    backgroundColor: '#3e98c7',
  });

const PROGRESS_STYLE_GREEN =
  buildStyles({
    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
    strokeLinecap: 'butt',

    // How long animation takes to go from one percentage to another, in seconds
    pathTransitionDuration: 3.5,

    // Colors
    pathColor: `rgb(0, 102, 0`,
    textColor: 'rgb(0, 102, 0',
    trailColor: '#d6d6d6',
    backgroundColor: '#3e98c7',
  });


class EvaluationApp extends Component {
  constructor(props) {
    super(props);
    this.updateStatistics = this.updateStatistics.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  updateStatistics(busType) {

  }

  render() {
    return (
      <Router>
        <ScrollToTop>
          <Switch>
            {
              Object.entries(MISC_PAGES).map(([path, content]) =>
                <Route path={path}>
                  {content}
                </Route>
              )
            }
            <Route path="/evaluation">
              <div className={styles.evalContainer}>
              <div className={styles.credits}>
                <a target="_blank" href="https://www.freepik.com/free-photos-vectors/background">Background vector created by katemangostar - www.freepik.com</a>
                </div>
                <div className={styles.evalContent}>
                  <h1>Statistiken</h1>
                  <span>
                    So sehen die Entscheidungen anderer Reisender aus:
                  </span>

                  <hr className={styles.separator} />


                  <div className={styles.statBox}>
                    <h2>So haben sich die Reisenden am <b>Anfang</b> entschieden:</h2>
                    <div className={styles.statBoxContent}>
                      <div className={styles.circProgress}>
                        <CircularProgressbar styles={PROGRESS_STYLE_BLUE} value={56} text={56 + `%`} />
                      </div>

                      <div className={styles.busLabel} onClick={(e) => this.updateStatistics('classic')}>
                        <img src={classicBus}></img>
                        <span> Bus mit Busfahrer </span>
                      </div>
                    </div>
                  </div>

                  <div className={styles.statBox}>
                    <div className={styles.statBoxContent}>
                      <div className={styles.circProgress}>
                        <CircularProgressbar styles={PROGRESS_STYLE_RED} value={44} text={44 + `%`} />
                      </div>

                      <div className={styles.busLabel} onClick={(e) => this.updateStatistics('classic')}>
                        <img src={modernBus}></img>
                        <span>Autonom fahrender Bus</span>
                      </div>
                    </div>
                  </div>

                  <hr className={styles.separator} />

                  <div className={styles.statBox}>
                    <h2>So haben sich die Reisenden am <b>Ende</b> entschieden:</h2>
                    <div className={styles.statBoxContent}>
                      <div className={styles.circProgress}>
                        <CircularProgressbar styles={PROGRESS_STYLE_BLUE} value={56} text={64 + `%`} />
                      </div>

                      <div className={styles.busLabel} onClick={(e) => this.updateStatistics('classic')}>
                        <img src={classicBus}></img>
                        <span> Bus mit Busfahrer </span>
                      </div>
                    </div>
                  </div>

                  <div className={styles.statBox}>
                    <div className={styles.statBoxContent}>
                      <div className={styles.circProgress}>
                        <CircularProgressbar styles={PROGRESS_STYLE_RED} value={44} text={36 + `%`} />
                      </div>

                      <div className={styles.busLabel} onClick={(e) => this.updateStatistics('classic')}>
                        <img src={modernBus}></img>
                        <span>Autonom fahrender Bus</span>
                      </div>
                    </div>
                  </div>

                  <hr className={styles.separator} />
                  <div className={styles.statBox}>
                    <h2>So viele Reisende haben sich umentschieden:</h2>
                    <div className={styles.statBoxContent}>
                      <div className={styles.circProgress}>
                        <CircularProgressbar styles={PROGRESS_STYLE_GREEN} value={13} text={13 + `%`} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Route>

            <Route path="/">
            <BusChoice headline={"Endstation"} routeMap={{classic: '/evaluation', modern: '/evaluation'}}>
                <span style={{width: "60%"}}>
                  <p>
                    Wir sind nun am Ende unserer gemeinsamen Reise angekommen. 
                    Wie du dir vielleicht denken kannst, ist die Welt der Künstlichen Intelligenz noch viel größer. 
                    Ich konnte euch nur einen kleinen Ausschnitt zeigen und hoffe, Stationen ausgewählt zu haben, die du auch so interessant fandest wie ich.
                    Es gibt aber noch viel mehr zu entdecken. 
                    Mich als Reiseführer hast du dabei aber nicht mehr an deiner Seite. 
                    Es hat mich gefreut, dich und deine Gruppe begleiten zu dürfen.
                  </p>
                  <p>
                    Schau nur, draußen stehen schon die Busse für eure Weiterreise bereit. 
                    Du hast nun erneut die Wahl: In welchen Bus willst du jetzt einsteigen? 
                    Hast du deine Meinung durch das neue Wissen vielleicht geändert? 
                    Wähle nun erneut: Autonom oder doch lieber mit Fahrer?
                  </p>
                  <p>
                    Danke und Tschüss!
                  </p>
                </span>
            </BusChoice>
            </Route>
          </Switch>
          <Footer />
        </ScrollToTop>
      </Router>
    );
  }
}

export default EvaluationApp;
