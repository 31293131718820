
import React, { Component } from 'react';
import common from './common.module.css';
import ReactDOM from 'react-dom';

import { MISC_PAGES } from './MiscPages';

import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

import ScrollToTop from 'react-router-scroll-top';

//import { AnimatedSwitch } from 'react-router-transition';


import StatementsPage from './StatementsPage';
import SingleTopicPage, { Section, ReferenceList, ReferenceItem } from './SingleTopicPage';
import EvaluationApp from './EvaluationApp';
import Footer from './Footer';
import Header from './Header';
import AppContainer from './AppContainer'

import IntroductionPage from './IntroductionPage';
import NextPageSwitch from './NextPageSwitch'



class App extends Component {
  constructor(props) {
    super(props);
    this.handleSwitchPage = this.handleSwitchPage.bind(this);
    this.state = { page: 0 };

    const busType = this.props.busType;
    const stations = this.props.stations;
    const indices = [0, 1, 2];

    this.stationRefs = [React.createRef(), React.createRef(), React.createRef(),
    React.createRef(), React.createRef(), React.createRef(),
    React.createRef(), React.createRef(), React.createRef()];

    this.articles = [
      <SingleTopicPage
        key="TP1"
        title={stations[0].title}
        name={stations[0].name}
        teaser={stations[0].teaser}
        article={stations[0].article}
        references={stations[0].references}
        handleSwitchPage={(e) => this.handleSwitchPage(e, 2)}
      />,
      <SingleTopicPage
        key="TP2"
        title={stations[1].title}
        name={stations[1].name}
        teaser={stations[1].teaser}
        article={stations[1].article}
        references={stations[1].references}
        handleSwitchPage={(e) => this.handleSwitchPage(e, 5)}
      />,
      <SingleTopicPage
        key="TP3"
        title={stations[2].title}
        name={stations[2].name}
        teaser={stations[2].teaser}
        article={stations[2].article}
        references={stations[2].references}
        handleSwitchPage={(e) => this.handleSwitchPage(e, 8)}
      />
    ];

    this.introductions = [
      <IntroductionPage title={stations[0].title} name={stations[0].name} intro={stations[0].intro} handleSwitchPage={(e) => this.handleSwitchPage(e, 1)} />,
      <IntroductionPage title={stations[1].title} name={stations[1].name} intro={stations[1].intro} handleSwitchPage={(e) => this.handleSwitchPage(e, 4)} />,
      <IntroductionPage title={stations[2].title} name={stations[2].name} intro={stations[2].intro} handleSwitchPage={(e) => this.handleSwitchPage(e, 7)} />
    ];

    const guideContent = (
      <div className={common.guideContent}>
        <div className={common.guideImage}></div>
        <article className={common.guideText}>
          <h1>Zurück im Bus: Was sagen Deine Mitfahrer dazu?</h1>
          <p>
            <b>Wie es funktioniert:</b> Im Bus (siehe unten) befindet sich deine Reisegruppe.
            Die Aussagen von den einzelnen Personen erscheinen nach einem Klick auf die entsprechenden Sprechblasen.
              Die Sprechblase kann mit einem Klick auf das <strong>&times;</strong> (Kreuz) am oberen rechten Rand wieder geschlossen werden.
        </p>
        </article>
      </div>
    );

    this.opinions = [
      <StatementsPage key="TP3"
        title={stations[0].title}
        name={stations[0].name}
        teaser={stations[0].teaser}
        busType={busType}
        statements={stations[0].statements}
      >
        {guideContent}
        <NextPageSwitch to={"/introduction-" + stations[1].name} onClick={(e) => this.handleSwitchPage(e, 3)} />
      </StatementsPage>,
      <StatementsPage
        key="TP1"
        title={stations[1].title}
        name={stations[1].name}
        teaser={stations[1].teaser}
        busType={busType}
        statements={stations[1].statements}
      >
        {guideContent}
        <NextPageSwitch to={"/introduction-" + stations[2].name} onClick={(e) => this.handleSwitchPage(e, 6)} />
      </StatementsPage>,
      <StatementsPage
        key="TP2"
        title={stations[2].title}
        name={stations[2].name}
        teaser={stations[2].teaser}
        handleSwitchPage={(e) => this.handleSwitchPage(e, 6)}
        busType={busType}
        statements={stations[2].statements}
      >
        {guideContent}
        <NextPageSwitch to={""} onClick={(e) => this.openEvaluationApp()} />
      </StatementsPage>,
    ];

    this.routes = indices.map((idx) => [
      <Route path={"/introduction-" + this.props.stations[idx].name}>
        {this.introductions[idx]}
        <Footer />
      </Route>,
      <Route path={"/article-" + this.props.stations[idx].name}>
        {this.articles[idx]}
        <Footer />
      </Route>,
      <Route path={"/opinion-" + this.props.stations[idx].name}>
        {this.opinions[idx]}
        <Footer />
      </Route>,
    ]
    ).concat(
      Object.entries(MISC_PAGES).map(([path, content]) =>
        <Route path={path}>
          {content}
          <Footer />
        </Route>
      )
    ).concat(
      [
        <Route path={"/" + this.props.stations[0].name}>
          {this.introductions[0]}
          <Footer />
        </Route>
      ]
    );
  }

  moveBusObject(index) {
    var busObject = document.getElementById('bus-object');
    const endPosLeft = this.stationRefs[index].current.offsetLeft - 70;
    const pos = parseInt(window.getComputedStyle(busObject).left);

    if (pos > endPosLeft) {
      busObject.style.transform = "scaleX(-1)";
    }
    else {
      busObject.style.transform = "scaleX(1)";
    }
    busObject.style.left = endPosLeft + 'px';
  }

  handleSwitchPage(e, stationIndex) {
    this.setState({ page: stationIndex });
    this.moveBusObject(stationIndex);
  }

  openEvaluationApp() {
    ReactDOM.render(
      <EvaluationApp stations={this.props.stations} busType={this.props.busType} />,
      document.getElementById('root')
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const miscPageNames = ['/about', '/privacy', '/imprint', '/license', '/dialogue', '/credits'];

    return (
      <Router>
        <ScrollToTop>
          <AppContainer miscPageNames={miscPageNames}>
            <Header
              activePage={this.state.page}
              refs={this.stationRefs}
              stations={this.props.stations}
              busType={this.props.busType}
              handleSwitchPage={this.handleSwitchPage}
            />
            <Switch>
              {this.routes}
            </Switch>
          </AppContainer>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
