import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import BusChoiceApp from './BusChoiceApp';

import iconMedical from './images/flaticon/svg/001-caduceus.svg';
import iconLabor from './images/flaticon/svg/005-wrench.svg';
import iconCar from  './images/flaticon/svg/004-car-compact.svg';

import teaserMedical from './images/unsplash/hospital-teaser-m.jpg';
import teaserLabor from './images/unsplash/jobs-teaser-m.jpg';
import teaserCar from './images/unsplash/mobility-teaser-m.jpg';


/* Zitate für Station: Krankenhaus */

import audioHospitalMast from  './interviews/hospital/hanna_sophie_mast.mp3'; 
import audioHospitalHeller from  './interviews/hospital/horst_heller.mp3'; 
import audioHospitalKsobiak from  './interviews/hospital/markus_ksobiak.mp3'; 
import audioHospitalHerzig from  './interviews/hospital/robert_herzig.mp3'; 
import audioHospitalKoenig from  './interviews/hospital/tina_koenig.mp3'; 

/* Zitate für Station: Arbeitsplätze */

import audioLaborMast from  './interviews/labor/hanna_sophie_mast.mp3'; 
import audioLaborHeller from  './interviews/labor/horst_heller.mp3'; 
import audioLaborGrabert from  './interviews/labor/peter_grabert.mp3'; 
import audioLaborHerzig from  './interviews/labor/robert_herzig.mp3'; 


/* Zitate für Station: Mobilität */

import audioMobilityKoenig from   './interviews/mobility/tina_koenig.mp3'; 
import audioMobilityMast from  './interviews/mobility/hanna_sophie_mast.mp3'; 
import audioMobilityHerzig from  './interviews/mobility/robert_herzig.mp3'; 

/* Zitate für den Bus, in den man einsteigt, wenn man „Bus ohne Fahrer“ wählt: */

//import audioBusMende from './interviews/bus/hanna_sophie_mast.mp3'; 
import audioBusMast from  './interviews/bus/hanna_sophie_mast.mp3'; 
import audioBusKoenig from  './interviews/bus/tina_koenig.mp3'; 
import audioBusKsobiak from  './interviews/bus/markus_ksobiak.mp3'; 

/* Zitate für den Bus, in den man einsteigt, wenn man „Bus mit Fahrer“ wählt: */

import audioBusHeller from  './interviews/bus/horst_heller.mp3'; 
//import audioBusDehnen from './interviews/bus/peter_grabert.mp3'; 
//import audioBusKuntermann from './interviews/bus/peter_grabert.mp3'; 
//import audioBusMairhofer from './interviews/bus/peter_grabert.mp3'; 
import audioBusHerzig from  './interviews/bus/robert_herzig.mp3'; 

/* Zitate für beide Wahlmöglichkeiten: */

//import audioBusGrabert from './interviews/bus/peter_grabert.mp3'; 

/* Test Zitat */

import audioInterview01 from './interviews/interview_test.mp3'; 

/* Profilbilder */

import profilePhotoMast from './images/profiles/512/hanna_sophie_mast.png';
import profilePhotoHeller from './images/profiles/512/horst_heller.png';
import profilePhotoGrabert from './images/profiles/512/peter_grabert.png';
import profilePhotoHerzig from './images/profiles/512/robert_herzig.png';
import profilePhotoMende from './images/profiles/512/dirk_mende.png';
import profilePhotoKoenig from './images/profiles/512/tina_koenig.png';
import profilePhotoKsobiak from './images/profiles/512/markus_ksobiak.png';
import profilePhotoKuntermann from './images/profiles/512/karin_kuntermann.png';
import profilePhotoMairhofer from './images/profiles/512/margit_mairhofer.png';
import profilePhotoDehnen from './images/profiles/512/joshua_dehnen.png';


/* Test Profilbild */

import interviewPhotoTeaser from './images/profiles/512/avatar.png';

const DECISIONS =
{
    /* Bus ohne fahrer */
    modern: [
      {name: "Dirk Mende ", sex:"male", profession: "?", audio: null, photo: profilePhotoMende, 
      texts: [`Ich hätte den Bus genommen mit einem Fahrer, der nach Tarif bezahlt wird, denn bei ihm hätte ich
      eine höhere Wahrscheinlichkeit, unbeschädigt anzukommen als mit einem automatisierten, der
      eventuell von irgendwelchen launigen Hackern in ganz andere Richtung oder in eine Schlucht
      gestürzt wird.`]},
      {name: "Hanna Sophie Mast ", sex:"female", profession: "Studentin", audio: audioBusMast, photo: profilePhotoMast, 
      texts: [`Ich hätte den Bus mit Fahrer genommen, weil ich es schön finde, jemanden begrüßen zu können
      und weil ich auch die Technologie, die hinter autonomem Fahren steckt, als diskussionswürdig
      erachte.`]},
      {name: "Tina König ", sex:"female", profession: "?", audio: null, photo: profilePhotoKoenig, 
      texts: [`Ich hätte mich für den Bus mit Fahrer entschieden, weil mir KI im Moment noch viel zu
      unausgereift ist, noch zu wenig erforscht und ich glaube, dass es manchmal gut wär, einen
      einzusetzen, aber nicht immer und da bin ich auch dafür, dass lieber Menschen da sind als Roboter
      oder Technik, KI.`]},
      {name: "Markus Ksobiak ", sex:"male", profession: "?", audio: audioBusKsobiak, photo: profilePhotoKsobiak, 
      texts: [`Ich hätte den Bus mit Fahrer genommen, weil ich möchte, dass der Fahrer weiterhin beschäftigt ist
      und künstliche Intelligenz mich nicht durch die Gegend fahren soll.`]}
    ],
    /* Bus mit fahrer */
    classic: [
      {name: "Horst Heller", sex:"male", profession: "Unternehmensberater", audio: audioBusHeller, photo: profilePhotoHeller,
      texts: [`Ich hätte den Bus ohne Fahrer genommen, weil ich denke, da passieren weniger Unfälle und es ist
      sicherer.`]},
      {name: "Joshua Dehnen", sex:"male", profession: "?", audio: null, photo: profilePhotoDehnen,
      texts: [`Ich hätte den autonom fahrenden Bus genommen, einfach weil ich aus dem Bereich komme und es faszinierend finde.`]},
      {name: "Karin Kuntermann", sex:"female", profession: "?", audio: null, photo: profilePhotoKuntermann,
      texts: [`Ich hätte den Bus ohne den Fahrer genommen, weil ich völlig entspannt drin sitzen könnte.`]},
      {name: "Margit Mairhofer", sex:"female", profession: "?", audio: null, photo: profilePhotoMairhofer,
      texts: [`Ich hätte den Bus ohne Fahrer genommen, weil ich auf das autonome Fahren vertraue.`]},
      {name: "Robert Herzig", sex:"male", profession: "KI-Entwickler", audio: audioBusHerzig, photo: profilePhotoHerzig,
      texts: [`Ich würde den autonom gesteuerten Bus nehmen, weil ich denke, dass
      die gesellschaftliche Akzeptanz und damit indirekt auch die wirtschaftliche Förderung damit
      unterstützt werden kann.`]}
    ]
};


const STATIONS = [
  /*
  {
    title: 'KI im Allgemeinen', 
    icon: iconAi, 
    name: 'ai', 
    teaser: teaserAi,
    statements: [
      {name: "Antoine", sex:"male", profession: "Hey, kein Plan", audio: audioInterview01, photo: interviewPhotoTeaser, texts:[]},
      {name: "Helene", sex:"female", profession: "Schauspielerin", audio: audioInterview01, photo: interviewPhotoTeaser, texts:[]}
    ]
  },
  */
  {
    title: 'Krankenhaus', 
    icon: iconMedical, 
    name: 'medical', 
    teaser: teaserMedical,
    intro: [
      "Hier erfährst du etwas über den Einsatz Künstlicher Intelligenz im Bereich der Medizin. Zunächst wird erläutert, inwiefern der Einsatz von Operationsrobotern chirurgische Eingriffe erleichtern kann. Es werden jedoch auch Grenzen dieser Technologie aufgezeigt.",
      "Danach geht es wieder in den Bus. Du siehst zu diesem Thema Stimmen und Meinungen von einigen Bürgern. Hier kannst du erfahren, was sie von der Anwendung Künstlicher Intelligenz im medizinischen Bereich halten.",
      "Nächster Halt: Jobcenter"
    ],
    article: [
      {
        headline: "Da Vinci als Assistent im OP",
        paragraphs: [
            "Stellen Sie sich einen sterilen, kühlen Operationssaal vor. Ein Patient liegt auf dem Operationstisch, daneben wacht ein Narkosearzt über die Lebenszeichen. Doch keine Spur eines Chirurgen. Die Suche führt in die andere Ecke des Raumes: Dort sitzt der behandelnde Arzt vor einem Monitor und lenkt mittels einer Konsole Roboterarme, die bis in den Körper des Patienten reichen. ",
            "Klingt wie eine Szene aus einem Science-Fiction-Film, ist aber bereits Alltag in rund 100 deutschen Kliniken. Ausgebildete Chirurgen verwenden den sogenannten „Da Vinci“ Operationsroboter zur Unterstützung ihrer Arbeit. Sie kommen insbesondere bei urologischen und gynäkologischen Eingriffen zum Einsatz. Durch das roboter-assistierte Chirurgiesystem kann beispielsweise die operative Entfernung der Prostata minimalinvasiv durchgeführt werden. Bei einer konventionellen, offenen Operation legt der Chirurg den Zugang zu dem betroffenen Organ komplett frei. Um den Eingriff bei einer herkömmlichen Operation ohne robotertechnische Unterstützung ausführen zu können, muss die entsprechende Körperhöhle weit geöffnet werden. Minimalinvasive Eingriffe ermöglichen dagegen den Zugang durch mehrere, meist nur wenige Millimeter lange Schnitte, wodurch schwierige Operationen im Brust- und Bauchraum auf schonendere Art und Weise umgesetzt werden. Über diese Öffnungen werden spezielle Instrumente des Roboters und die Kameraoptik eingeführt. Der Operateur lenkt in Echtzeit drei Arme des Roboters und setzt die daran befestigten Instrumente in Bewegung. Ein vierter Arm liefert über eine montierte Kamera vergrößerte 3D-Bilder des Operationsfeldes."
        ],
        aside: "Roboter-assistierte Chirurgiesysteme erlauben minimalinvasive Eingriffe"
      },
      {
        headline: "Präziser als jedes Skalpell?",
        paragraphs: [
            "Das Unternehmen „Intuitive Surgical“ behauptet, dass Operateure durch den Einsatz ihrer Da Vinci Roboter insbesondere von akkurateren Arbeitstechniken profitieren. Das optisch bis zu zehnfach vergrößerte Operationsfeld mache feinste Strukturen sichtbar. Menschliche Nebeneffekte, wie nicht stringent kontrollierbare Handgriffe, werden herausgefiltert und in exakte Bewegungen umgewandelt. Nicht zuletzt erhalten die Ärzte durch das System eine entspannte Sitz-, Körper- und Armhaltung, die besonders in lang andauernden Operationen mögliche Schmerzen ausräumen. Gegenüber den Patienten lautet das Versprechen: Weniger Narben durch kleinere Einschnitte, schnellere Wundheilung, weniger Schmerzen und damit einen verkürzten Krankenhausaufenthalt. Klingt plausibel, woher also die Skepsis mancher Bürger? Bislang gibt es keine Langzeitstudie, die signifikante Vorteile von roboter-assistierten Einsätzen gegenüber normal minimalinvasiven Eingriffen ohne Robotertechnik untermauert. Zudem ist der Einsatz mit hohen finanziellen Ausgaben verbunden, denn die Anschaffungskosten für eine Klinik belaufen sich pro Gerät auf bis zu zwei Millionen Euro. Hinzu kommen Ausgaben für entsprechende Instrumente, die nach zehnmaligem Einsatz ausgetauscht werden müssen – laut Skeptikern ein Schachzug des Herstellers der Da Vinci Roboter, der damit seine Monopolstellung ausnutze. ",
            "Fest steht: Entscheidungen treffen die Roboter nicht, sie führen nur jene Handlungen aus, die der Operateur vorgibt. Doch die Forschung steht nicht still. Experten prognostizieren den autonomen Einsatz von Robotern in Operationssälen für die kommenden zwanzig Jahre. Zunächst werden die Chirurgen aber auch künftig das Skalpell führen. Dr. Roboter bleibt Assistent. "
        ],
        aside: "Entscheidungen treffen die Roboter nicht"
      }
    ],
    references: [
      {
        authors: 
              "Alkatout, I., Maass, N., Egberts, J.-H., Jünemann, K.-P., Ackermann, J., & Kimmig, R. (2016).",
        title: 
              "Roboterchirurgie in der Gynäkologie – Status quo. Der Gynäkologe, 49(6), 470–476.",
        url: "https://link.springer.com/article/10.1007%2Fs00129-016-3881-6"
      },
      {
        authors: 
              "Bublak, R., & Römer, T. (2012).",
        title: 
              "Roboter braucht länger für die Myomektomie. gynäkologie + geburtshilfe, 17(5), 17–17.",
        url: "https://link.springer.com/content/pdf/10.1007%2Fs15013-012-0194-2.pdf"
      },
      {
        authors: 
              "Gesierich, W. (2019).",
        title: 
              "Roboter in der Bronchoskopie? Pneumo News, 11(8), 10–11.",
        url: "https://link.springer.com/content/pdf/10.1007/s15033-019-1333-4.pdf"
      },
      {
        authors: 
              "Schwaibold, H. (2014).",
        title: 
              "Beleuchtung bestehender Indikationen. Uro-News, 18(10), 18–20.",
        url: "https://link.springer.com/article/10.1007%2Fs00092-014-0736-0"
      },
      {
        authors: 
              "Weißbach, L., & Bühmannstehen, W. (2012).",
        title: 
              "Berührungsfreie Urologie—Eine richtige Entwicklung? Uro-News, 16(7), 14–15.",
        url: "https://link.springer.com/content/pdf/10.1007%2Fs00092-012-0291-5.pdf"
      },
      {
        authors: 
              "Huss, R. (2019).",
        title: 
              "Künstliche Intelligenz, Robotik und Big Data in der Medizin. Springer.",
        url: "https://www.springer.com/de/book/9783662581506"
      }
    ],
    statements: [
      {name: "Dirk Mende ", sex:"male", profession: "?", audio: null, photo: profilePhotoMende, 
      texts: [
      /* 
        `Das würde ich jetzt davon abhängig machen, wie meine Vorinformationen sind. So eindeutig kann
      ich das nicht beurteilen. Also mir ist es klar, dass in bestimmten Bereichen eine höchste Präzision
      hierbei schon erreicht ist, aber das käme dann auch auf die Art der Operation an. Ich würde auch
      immer alles so entscheiden, dass ich mich dann erst zu einer solchen Maßnahme entscheiden
      würde, wenn genügend verlässliche Daten vorliegen. So wäre meine Position.`,         
      */

      `Da bestünde doch diese schöne Möglichkeit, beide zu Rate zu ziehen und dann eine Entscheidung zu treffen.`]},
      {name: "Hanna Sophie Mast ", sex:"female", profession: "Studentin", audio: audioHospitalMast, photo: profilePhotoMast, 
      texts: [`Ich glaube letztendlich sind wir als Menschen
      darauf gepolt, eher Menschen zu vertrauen als Maschinen. Einfach weil wir irgendwie auf
      menschliche Interaktion, weil wir so lernen, weil wir so aufwachsen, weil wir eben nie nur als
      einzelnes Wesen existiert haben zu keinem Zeitpunkt in unserem Leben. Deswegen würde ich
      aus dem Bauch heraus sagen, ich würde immer Menschen mehr vertrauen als Maschinen.`]},

      {name: "Horst Heller", sex:"male", profession: "Unternehmensberater", audio: audioHospitalHeller, photo: profilePhotoHeller,
      texts: [`Nach heutigem Stand nicht. Vielleicht nach dem Stand in 5 Jahren. Weil ich denke nach dem
      heutigen Stand sind wir noch nicht soweit. Da habe ich ein bisschen Angst um meinen
      Körper.`]},

      {name: "Joshua Dehnen", sex:"male", profession: "?", audio: null, photo: profilePhotoDehnen,
      texts: [
      `Das kommt dann auf das Verhältnis, auf die Authentizität an. Wenn ich mit dem Roboter dann
      schon reden kann wie mit einem Menschen, ohne dass er Ausfälle hat, will ich nicht ausschließen,
      dass ich das dann auch zulassen werde.`]},

      {name: "Karin Kuntermann", sex:"female", profession: "?", audio: null, photo: profilePhotoKuntermann,
      texts: [`Das werde ich nicht mehr erleben, das wird ja schon
      noch ein paar Jahrzehnte dauern, dass nur eine KI operiert. Aber rein mit der Unterstützung von
      KI selbstverständlich.`]},

      {name: "Margit Mairhofer", sex:"female", profession: "?", audio: null, photo: profilePhotoMairhofer,
      texts: [`Also ich würde dem menschlichen Arzt mehr vertrauen. Weil mit dem kann ich reden, ich kann
      Fragen stellen. Das schafft für mich Sicherheit.`]},

      {name: "Peter Grabert", sex:"male", profession: "", audio: null, photo: profilePhotoGrabert,
      texts: [`Auf jeden Fall. Wenn damit vielleicht die Chance, dass ich überlebe oder besser überlebe, größer
      wäre.`, `Nein, Menschen würde ich schon noch dabei haben wollen und da wäre mir
      der Faktor extrem wichtig. Gerade bei älteren Leuten ist dieser Faktor glaube ich extrem groß.`]},

      {name: "Robert Herzig", sex:"male", profession: "KI-Entwickler", audio: audioHospitalHerzig, photo: profilePhotoHerzig,
      texts: [`Generell dadurch, dass das Ganze immer noch großteils regelbasiert ist, ist eine KI
      vorhersehbar. Das heißt, effektiv würde ich sagen, das Vertrauen gegenüber der Maschine
      ist eigentlich größer, weil ich nicht dieses menschliche Fehlverhalten, was z.B. durch Stress
      entsteht usw. habe. Das heißt, ich habe die Resistenz, ich kann 24 Stunden Operationen
      durchführen. Jeder Chirurg ist dabei total am Ende. Eine Maschine macht einfach weiter und
      funktioniert wie in der ersten Minute.`]},

      {name: "Markus Ksobiak ", sex:"male", profession: "?", audio: audioHospitalKsobiak, photo: profilePhotoKsobiak, 
      texts: [`Ich glaube schon, dass irgendwann die Künstliche Intelligenz und damit Robotersysteme bei der
      Operation auch eine sehr, sehr wichtige Rolle spielen werden. Da wird ja jetzt schon sehr nah dran
      geforscht und es ist durchaus denkbar, dass der ein oder andere Chirurg dadurch seinen
      Arbeitsplatz verliert und die Präzision der Operation bei weitem bessere Ergebnisse liefern wird.
      Ich glaube aber, man muss auch da an ein hybrides Modell denken, wo ein genauso intelligenter
      Mensch die Sache beobachtet, was die Maschine da gerade macht.`]},

      {name: "Tina König ", sex:"female", profession: "?", audio: audioHospitalKoenig, photo: profilePhotoKoenig, 
      texts: [`Also ich glaube, dass gerade der medizinische Bereich ein wichtiger Bereich ist, wo KI
      sinnvoll sein kann, weil es da sehr auf Präzisionsarbeit ankommt und der Mensch eben doch
      Schwächen hat. Ich würde das wohl auch machen, wenn es genug Ergebnisse gibt, dass alles
      super läuft und nach wie vor jeder Mensch die Entscheidung hat, möchte ich von einem
      Roboter operiert werden oder von einem Menschen und dass das nicht irgendwann so
      automatisch ersetzbar ist. Das wäre für mich wichtig.`]}
    ]
  },
  {
    title: 'Jobcenter', 
    icon: iconLabor, 
    name: 'labor', 
    teaser: teaserLabor,
    intro: [
      "Hier erfährst du etwas über den Einsatz von Künstlicher Intelligenz im Bereich des Arbeitsplatzes. Nehmen uns Maschinen wirklich die Arbeit weg? Darum wird es in dem nächsten Artikel gehen. Neben den Risiken werden auch Chancen genannt wie Künstliche Intelligenz positiv Einklang in der Arbeitswelt finden kann.",
      "Danach geht es wieder in den Bus. Du siehst Stimmen und Meinungen zu diesem Thema von einigen Bürgern. Hier kannst du erfahren was sie über den Einsatz von Künstlicher Intelligenz im Bereich des Arbeitsplatzes halten."
    ],
    article: [
      {
        headline: "Künstliche Intelligenz und der Wandel des Arbeitsmarkts",
        paragraphs: [
          "Seit 2013 hallt ein großer Aufschrei durch die Medienlandschaft, denn Wissenschaftler der renommierten University of Oxford stellten folgende Behauptung auf: Beinahe die Hälfte der Beschäftigten in den westlichen Staaten arbeiten derzeit in Berufen, die in den nächsten zehn bis zwanzig Jahren mit hoher Wahrscheinlichkeit automatisiert werden können. Künstliche Intelligenzen würden den Menschen ihre Arbeitsplätze streitig machen, weil immer mehr Routinearbeiten in Algorithmen übertragen werden. Aber stimmt das? Nehmen uns Maschinen wirklich die Arbeit weg?",
          "Komplett aus der Luft gegriffen ist diese These nicht.  Unternehmen investieren immer mehr in automatisierte Systeme. Anfang 2018 sorgte die Modeplattform Zalando für Schlagzeilen. Knapp 250 Mitarbeiter wurden im Marketing durch intelligente Software ersetzt, welche seitdem automatisiert Werbung betreibt. Mittels Künstlicher Intelligenz erhoffen sich Unternehmen wie Zalando höhere Gewinne, weil dadurch Lohnkosten eingespart werden können. "
        ],
        aside: "Nehmen uns Maschinen wirklich die Arbeit weg?"
      },
      {
        headline: "Vom Verlust und Gewinn für Arbeitsplätze",
        paragraphs: [
          "KI-Experten wie Shirley Ogolla (Wissenschaftliche Mitarbeiterin am Alexander von Humboldt Institut für Internet und Gesellschaft) sehen darin aber keinen Grund zur Panik:  „Wenn wir heute intelligente Systeme einsetzen, dann vor allem, um einzelne Tätigkeiten in einem Arbeitsprozess zu automatisieren, nicht den kompletten Job auf einmal.“ Ihrer Ansicht nach  verspricht  der Einsatz von KI vielmehr die Chance, die Arbeit von Mitarbeitern aufzuwerten und sie bei ihren Tätigkeiten zu entlasten. Simple lästige Aufgaben können nämlich an ein automatisiertes System abgegeben werden. ",
          "Fest steht:  Viele einfache Arbeiten, die kein individuelles Eingreifen erfordern, übernehmen zukünftig Algorithmen.  Bestimmte Jobs sind somit mehr gefährdet als andere. Montageberufe in Industriehallen werden wahrscheinlich besonders betroffen sein, ebenso Bürokräfte von Versicherungen oder Banken. Im Gegenzug werden mit der Digitalisierung des Arbeitsmarktes viele neue Arbeitsplätze geschaffen. Zalando schuf nach der Entlassung der Marketingmitarbeiter 2000 neue Jobs im IT-Bereich. Spezialisten, die digitale Daten analysieren, aufbereiten und einsetzen können, sind sehr gefragt. Unklar ist, ob der befürchtete Verlust von Arbeitsplätzen tatsächlich  durch den Bedarf an neuen Jobs kompensiert werden kann.",
        ],
        aside: "Bestimmte Jobs sind mehr gefährdet als andere"
      },
      {
        headline: "Herausforderungen des wandelnden Arbeitsmarktes angehen",
        paragraphs: [
          "Sicher ist jedoch, die alltägliche Arbeit wird sich verändern.  Darum ist es ratsam, dass sich jede Arbeitskraft schon heute für die Ausführung zukünftiger Tätigkeiten qualifiziert und sich mit dem technologischen Wandel von morgen beschäftigt. Ebenso müssen zukünftigen Generationen auf einen völlig neuen Arbeitsmarkt vorbereitet werden und einen flexiblen Umgang mit Soft- und Hardware von klein auf lernen.  Denn wenn die Herausforderungen der zunehmenden Automatisierung angegangen werden,  kann der Befürchtung Maschinen nähmen uns sie Arbeit weg, begegnet werden. "
        ],
        aside: "Die alltägliche Arbeit wird sich verändern"
      }
    ],
    references: [
      {
        authors: 
            "Frey, C. B., Osborne, M. A.:",
        title: 
            "The Future of Employment: How Susceptible are Jobs to Computerisation?, Oxford 2013",
        url: "https://www.oxfordmartin.ox.ac.uk/downloads/academic/The_Future_of_Employment.pdf"
      },
      {
        authors: 
            "Jansen, J.:",
        title: 
            "Zalando baut radikal um, FAZ 2018",
        url: "https://www.faz.net/aktuell/wirtschaft/digitec/zalando-will-werbefachleute-durch-entwickler-ersetzen-15483592.html"
      },
      {
        authors: 
            "Schirmer. S.:",
        title: 
            "Die Alternative ist: Irgendwann ist dein Arbeitsplatz fort, ZEIT 2019",
        url: "https://www.zeit.de/die-antwort/2019-11/kuenstliche-intelligenz-jobs-arbeit-mensch-maschine"
      },
      {
        authors: 
            "Terwey, A.:",
        title: 
            "Mensch gegen Maschine - Jobkiller oder Chance? Wie Künstliche Intelligenz den Arbeitsmarkt spaltet, Focus 2019",
        url: "https://www.focus.de/politik/deutschland/jobkiller-oder-chance-wie-kuenstliche-intelligenz-den-arbeitsmarkt-spaltet_id_10493321.html"
      },
      {
        authors: 
            "Wittpahl, V.:",
        title: 
            "Künstliche Intelligenzen – Technologie/Anwendung/Gesellschaft, Berlin 2019, S.221-283",
        url: "https://link.springer.com/content/pdf/10.1007%2F978-3-662-58042-4.pdf"
      }
    ],
    statements: [
      {name: "Hanna Sophie Mast ", sex:"female", profession: "Studentin", audio: audioLaborMast, photo: profilePhotoMast, 
      texts: [`Ich glaube wir alle, die zu der Generation gehören, die mit dem Internet als Realität groß geworden
      ist, haben schon relativ viel mit künstlicher Intelligenzen in unserem Alltag zu tun. Einfach über unser
      Smartphone, über die Art und Weise wie wir Apps benutzen usw. Deswegen finde ich ist das nicht so
      eine gruselige Vorstellung mit einer KI zusammenzuarbeiten. Wenn sich eben die Frage ob das
      wirklich was bringt und ob das irgendwie auch einen wünschenswerten Zweck erfüllt mit „Ja“
      beantworten lässt.`]},

      {name: "Horst Heller", sex:"male", profession: "Unternehmensberater", audio: audioLaborHeller, photo: profilePhotoHeller,
      texts: [`Ich berate, ich bin Unternehmensberater. Meine Arbeit kann es nicht übernehmen, weil ich berate persönlich. 
      Aber ich sehe eine Gefahr darin, dass 50% der Arbeitsplätze frei geschätzt werden.`,
      `Weil wir wissen nicht wo es hinläuft. Wir rennen wie bei der Atomkraft rein und wissen nicht über die
      Gefahren. Wir denken nicht nach.`
      ]},

      {name: "Peter Grabert", sex:"male", profession: "", audio: audioLaborGrabert, photo: profilePhotoGrabert,
      texts: [`Ich glaube eine KI könnte meine Arbeit in gewissen Grenzen übernehmen. Warum? Weil die Dinger
      einfach viel viel besser werden und so wie es bei vielen verschiedenen Sachen war, wo man früher
      gesagt hat das ist Science Fiction – 10 Jahre später war es dann Realität.`]},

      {name: "Margit Mairhofer", sex:"female", profession: "?", audio: null, photo: profilePhotoMairhofer,
      texts: [`Also ich bin Stadtführerin, ich glaube die könnte das übernehmen, ja. Man kann Audioguides schon
          jetzt haben und über die Smartphones sich Bilder dazu anschauen. Ja das kann man übernehmen.
          Denke ich schon.`]},

      {name: "Tina König ", sex:"female", profession: "?", audio: null, photo: profilePhotoKoenig, 
      texts: [`Also ich glaube dass wir ja schon durch KI beeinflusst werden durch Apps oder, was weiß ich, Geräte
          die wir nutzen. Also dann nutzen wir das ja schon. Und ich glaube auch, dass es in Bereichen der
          Arbeit sinnvolle Einsatzmöglichkeiten gibt. Und wenn sie sinnvoll sind würde ich sie schon nutzen.
          Aber sie sollen nicht den Menschen ersetzen, grundsätzlich.`]},

      {name: "Robert Herzig", sex:"male", profession: "KI-Entwickler", audio: audioLaborHerzig, photo: profilePhotoHerzig,
      texts: [`Also ja ich kann mir vorstellen mit einer KI zusammenzuarbeiten. Da gibt’s ganz viele
      unterschiedliche Felder wo das gut funktioniert.`,
      `Das man einfach Vorschläge bekommt von einer KI, die nochmal durchgeht und schaut okay, ist das ein guter Vorschlag oder ist das ein schlechter
      Vorschlag. Und ich finde das ist ein sehr guter Ansatz, der auch überall in der Wirtschaft oder in
      vielen Bereichen funktionieren kann.`
      ]}
    ]
  },
  {
    title: 'Industriegebiet', 
    icon: iconCar, 
    name: 'mobility', 
    teaser: teaserCar,
    intro: [
      "Du kannst den kurzen Halt nutzen, um dich über den Einsatz von Künstlicher Intelligenz im Bereich der Mobilität zu informieren. Ganz konkret geht es um autonomes Fahren, aber lies selbst!",
      "Danach geht es wieder in den Bus. Dort kannst du dich mit deinen Mitfahrern austauschen und erfahren, welche Meinungen sie im Hinblick auf Künstliche Intelligenz in der Mobilität vertreten.",
      "Nächster Halt: Krankenhaus"
    ],
    article: [
      {
        headline: "Augen zu und… ankommen?",
        paragraphs: [
          "Abbiege-, Spurhalte-, Nachtsicht- oder Einparkassistent: Viele Autofahrer sind nicht alleine auf den Straßen unterwegs. Abhängig von der Definition werden sie bereits heute von Künstlicher Intelligenz (KI) begleitet. Abhängig von der Definition deshalb, weil diese – wie in vielen KI-Einsatzbereichen – ganz unterschiedlich sein kann. Während mancher schon in Navigationsgeräten eine KI erkennt, spricht ein anderer erst bei selbstfahrenden Fahrzeugen davon.",
          "Sogenannte Fahrassistenzsysteme wie der Abbiegeassistent sind die Vorstufe zu autonomen, also selbstfahrenden und -entscheidenden Fahrzeugen. Die Entwicklung zum autonomen Fahren gliedert sich in insgesamt fünf Stufen oder Level: assistiertes (1), teilautomatisiertes (2), hochautomatisiertes (3), vollautomatisiertes (4) und autonomes (5) Fahren. Als zusätzliche Stufe oder Level Null wird das Fahren ohne jegliche Assistenzsysteme betrachtet."
        ],
        aside: "Fünf Stufen der Entwicklung des autonomen Fahrens"
      },
      {
        headline: "Vom Fahrer zum Passagier",
        paragraphs: [
            "Mit jeder Entwicklungsphase übernehmen Fahrassistenzsysteme mehr Funktionen und entwickeln sich weiter hin zu einer Künstlichen Intelligenz, die im fünften Level keinen Fahrer, sondern nur noch Passagiere befördern wird. Obwohl die Technik schon weiter fortgeschritten ist, befinden sich die Fahrzeuge auf Deutschlands Straßen aktuell zwischen der zweiten und der dritten Stufe – abgesehen von wenigen Testausnahmen.",
            "Während der Fahrer bei der zweiten Stufe noch jederzeit eingriffsbereit sein muss, kann er sich bei Fahrzeugen des dritten Levels unter bestimmten Bedingungen vom Verkehr abwenden. Sobald diese Bedingungen nicht mehr gegeben sind, fordert das System den Fahrer zum Eingreifen auf. Zudem hat der ahrer immer die Möglichkeit, über das System hinweg zu entscheiden. Der Einsatz solcher Systeme ist an rechtliche Rahmenbedingungen gekoppelt, welche momentan nicht erfüllt sind."
        ],
        aside: "Fahrzeuge auf Deutschlands Straßen aktuell zwischen der zweiten und der dritten Stufe"
        
      },
      {
        headline: "Ziel noch lange nicht erreicht",
        paragraphs: [
            "Zwar traten in Deutschland 2017 mit den Paragrafen 1a und 1b im Straßenverkehrsgesetz entsprechende Gesetze in Kraft, welche zumindest für die dritte Stufe den Weg ebnen. Jedoch werden diese von europäischen Beschränkungen überlagert, sodass solche – bereits entwickelten – Techniken bisher nur mit Ausnahme-Genehmigungen zum Einsatz kommen. Im Vergleich zu anderen Industrienationen wie den USA werden in Deutschland zudem viele Tests vorausgesetzt. Immer wieder wird der Vorrang der Sicherheit betont. Der Diskurs um Fragen der Haftung und Ethik ist in Deutschland längst in vollem Gange, so stellte eine vom Bundesverkehrsministerium beauftragte Expertenkommission beispielsweise ethische Regeln zum Thema auf.",
            "Dennoch muss sich noch viel ändern und entwickeln, bis der Fahrer nicht mehr nur unterstützt, sondern ersetzt werden kann – angefangen beim Ausbau des 5G-Netzes bis hin zur gesellschaftlichen Akzeptanz. „Frühestens 2025 bewegen sich Autos fahrerlos durch den Straßenverkehr“, erklärte das Bundesverkehrsministerium. Eine Prognos-Studie im Auftrag des ADAC spricht sogar davon, dass Fahrzeuge der vierten Stufe frühestens 2035 oder 2040 auf den Markt kommen, deren flächendeckende Verbreitung sei auch 2050 noch nicht zu erwarten. Autonome Level-5-Fahrzeuge sind in der bis 2050 reichenden Studie nicht beinhaltet."
        ],
        aside: "\„Frühestens 2025 bewegen sich Autos fahrerlos durch den Straßenverkehr\“"
      },
      {
        headline: "Oma on Tour?",
        paragraphs: [
            "So manchen dürfte das freuen, denn die Entwicklung stößt nicht nur auf positive Reaktionen. Skeptisch werden etwa der eigene Kontrollverlust, das Sammeln von Daten und mögliche Hackerangriffe betrachtet. Auf der anderen Seite werden hohe Erwartungen und Hoffnungen in autonomes Fahren gesetzt: Menschen auf dem Land, im Alter oder mit Einschränkungen wären mobiler. Außerdem könnte die Fahrzeit anders genutzt werden.",
            "Verlockend ist für viele zudem die Aussicht, die derzeit überfüllten Straßen problemlos zu befahren. Denn durch sinnvolle Planung und Vernetzung der Fahrzeuge würde es weniger Staus und Unfälle geben - Zeitersparnis und eine weniger ausgelastete Infrastruktur inklusive. Mittels autonomer Fahrsysteme könnte somit ressourcen- und umweltschonenderes Fahren ermöglicht werden."
        ],
        aside: "Lösung des Mobilitätsproblems?"
      },
    ],
    references: [
      {
        authors: "Altenburg, S., Kienzler, H.-P., Auf der Maur, A., Prognos im Auftrag des ADAC e. V. (2018).",
        title: "Einführung von Automatisierungsfunktionen in der Pkw-Flotte: Auswirkungen auf Bestand und Sicherheit.",
        url: "https://www.prognos.com/uploads/tx_atwpubdb/ADAC_Automatisiertes_Fahren_Endbericht_final.pdf"
      },
      {
        authors: 
              "Braun, S., Schatzinger, S., Schaufler, C., Rutka, C.-M., Fanderl, N. (2019).",
        title: 
              "Autonomes Fahren im Kontext der Stadt von morgen [AFKOS].",
        url: "http://publica.fraunhofer.de/starweb/servlet.starweb?path=urn.web&search=urn:nbn:de:0011-n-5436689"
      },
      {
        authors: 
              "Bundesministerium für Verkehr und digitale Infrastruktur (2017).",
        title:
              "Kompakt² - So fahren wir morgen.",
        url:"https://www.bmvi.de/SharedDocs/DE/Publikationen/G/kompakt-automatisiertes-fahren.pdf?__blob=publicationFile"
      },
      {
        authors: 
              "Bundesministerium für Verkehr und digitale Infrastruktur (2017).",
        title: 
              "Bericht zum Stand der Umsetzung der Strategie automatisiertes und vernetztes Fahren.",
        url: "https://www.bmvi.de/SharedDocs/DE/Publikationen/DG/bericht-avf.pdf?__blob=publicationFile"
      },
      {
        authors: 
              "Bundesministerium für Verkehr und digitale Infrastruktur (2017).",
        title: 
              "Ethik-Kommission Automatisiertes und vernetztes Fahren Bericht Juni 2017.",
        url: "https://www.bmvi.de/SharedDocs/DE/Publikationen/DG/bericht-der-ethik-kommission.pdf?__blob=publicationFile"
      },
      {
        authors: 
              "Bundesministerium für Verkehr und digitale Infrastruktur (2015).",
        title: 
              "Strategie automatisiertes und vernetztes Fahren - Leitanbieter bleiben, Leitmarkt werden, Regelbetrieb einleiten.",
        url: "https://www.bmvi.de/SharedDocs/DE/Publikationen/DG/broschuere-strategie-automatisiertes-vernetztes-fahren.pdf?__blob=publicationFile"
      },
      {
        authors: 
              "CosmosDirekt (2018).",
        title: 
              "Autonomes Fahren: Was die Deutschen über die neue Technik denken.",
        url: "https://www.cosmosdirekt.de/resource/blob/47006/ea16ac2a5f24e3e70f078a7d4e6ae90e/99-2018-04-25-autonomes-fahren-data.pdf"
      },
      {
        authors: 
              "Kunz, M., ADAC e. V. (2019).",
        title: 
              "Assistenzsysteme: So können sie Fahrer entlasten.",
        url: "https://www.adac.de/rund-ums-fahrzeug/ausstattung-technik-zubehoer/assistenzsysteme/fahrerassistenzsysteme/"
      },
      {
        authors: 
              "Kunz, M., ADAC e. V. (2019).",
        title: 
              "Autonomes Fahren: Digital entspannt in die Zukunft.",
        url: "https://www.adac.de/rund-ums-fahrzeug/ausstattung-technik-zubehoer/autonomes-fahren/technik-vernetzung/aktuelle-technik/"
      }
    ],
    statements: [
      {name: "Dirk Mende ", sex:"male", profession: "?", audio: null, photo: profilePhotoMende, 
      texts: [`Es würden gleich viele Autos die Straßen mit ihrem Blech zumüllen, Parkplatzprobleme wären im
      Prinzip auch die gleichen und eine Verkehrswende müsste komplett anders aussehen. Außerdem
      glaube ich nicht, dass jemand freiwillig das Recht, ein Auto selbstständig zu steuern, je aufgeben
      wird.`]},

      {name: "Horst Heller", sex:"male", profession: "Unternehmensberater", audio: null, photo: profilePhotoHeller,
      texts: [`Ich finde autonom fahrende Autos gut. Ich bin LKW gefahren, habe viele Unfälle gesehen und durch
      das autonome Fahren werden die Fahrer auch nicht übermüdet. Für mich sehe ich es als
      Entspannung. Man kann lesen im Auto, man kann sich auf ein Event vorbereiten, man kann die Firma
      führen, man kann Social Media machen, das ist genial.`]},

      {name: "Joshua Dehnen", sex:"male", profession: "?", audio: null, photo: profilePhotoDehnen,
      texts: [`Ich verbinde mit autonomem Fahren Beisterung, weil es viele neue Möglichkeiten bietet.`]},

      {name: "Karin Kuntermann", sex:"female", profession: "?", audio: null, photo: profilePhotoKuntermann,
      texts: [`Man muss ein bisschen Zuversicht haben. Nicht nur darüber reden, sondern es muss gemacht
      werden und dann gewöhnt man sich da dran wie man sich an alles Neue gewöhnt.`]},

      {name: "Margit Mairhofer", sex:"female", profession: "?", audio: null, photo: profilePhotoMairhofer,
      texts: [`Beim Gedanken an autonomes Fahren kommt bei mir eher Freude auf. Es ist eine Entspannung für
      mich, denn ich habe Zeit, was anderes zu machen, zum Beispiel kann ich ein Buch lesen oder einen
      Film gucken. Ich bin positiv eingestellt.`]},

      {name: "Peter Grabert", sex:"male", profession: "", audio: null, photo: profilePhotoGrabert,
      texts: [`Einerseits positiv wäre, dass der Straßenverkehr sicherlich sicherer wäre, andererseits ergeben sich
      natürlich auch Möglichkeiten, das Ganze zu manipulieren – also irgendwie ein System zu cracken, um
      irgendwas zu verfremden, was zu fälschen, um Daten abzugreifen. Es sind ja auch schon Autos
      geknackt worden, also ich fühle mich da nicht so ganz wohl. An eine Zukunft mit nur autonom
      fahrenden Autos glaub ich noch nicht, aber sie wird wohl irgendwann kommen, aber da bin ich
      wahrscheinlich schon lange nicht mehr hier.`]},

      {name: "Markus Ksobiak ", sex:"male", profession: "?", audio: null, photo: profilePhotoKsobiak, 
      texts: [`Für mich ist das Fahren mit Künstlicher Intelligenz oder das Nicht-Fahren ein Verlust, weil ich sehr
      gerne Auto fahre, ich würde dann andere Verkehrsmittel wählen. Ich glaube das autonome Fahren
      mit Einzelfahrzeugen oder Kleinfahrzeugen ist nicht die richtige Entscheidung. Wir haben schon über
      viele Jahre oder Jahrzehnte hinweg die Möglichkeit, andere Verkehrsmittel auszubauen. Diese
      Chancen haben wir nicht genutzt und da gibt es durchaus andere Systeme, die viel sinnvoller sind als
      einzelne Kisten durch die Gegend zu bewegen.`]},

      {name: "Tina König ", sex:"female", profession: "?", audio: audioMobilityKoenig, photo: profilePhotoKoenig, 
      texts: []},

      {name: "Hanna Sophie Mast ", sex:"female", profession: "Studentin", audio: audioMobilityMast, photo: profilePhotoMast, 
      texts: []},

      {name: "Robert Herzig", sex:"male", profession: "KI-Entwickler", audio: audioMobilityHerzig, photo: profilePhotoHerzig,
      texts: []}
    ]
  }
];


ReactDOM.render(
  <BusChoiceApp stations={[STATIONS[2], STATIONS[0], STATIONS[1]]} decisions={DECISIONS}/>,
  document.getElementById('root')
);

/*
ReactDOM.render(
  <EvaluationApp stations={STATIONS} busType={"classic"}/>,
  document.getElementById('root')
);
*/
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
