
import React, { Component } from 'react';
import styles from './Header.module.css';
import common from './common.module.css';

import Station from './StationElement';
import ModernBus from './images/custom/modern-bus.svg';
import ClassicBus from './images/custom/classic-bus.svg';


class Header extends Component {
  constructor(props) {
    super(props);
    this.handleSwitchPage = this.handleSwitchPage.bind(this);
  }

  handleSwitchPage(e, idx) {
    this.props.handleSwitchPage(e, idx);
  }

  render() {
    const stations = this.props.stations;
    const busType = this.props.busType;
    const bus = busType === "modern" ? ModernBus : ClassicBus;
    const activeStation = Math.floor(this.props.activePage / 3);

    const title = stations[activeStation].title;
    const teaser = stations[activeStation].teaser;

    const refs = this.props.refs;

    const c0 = "rgb(255, 255, 255)";

    const c1 = "rgb(229, 154, 154)";
    const c2 = "rgb(229, 209, 154)";
    const c3 = "rgb(154, 229, 191)";


    var colors = [c1, c1, c1, c2, c2, c2, c3, c3, c3];
    colors[this.props.activePage] = c0;

    return (
      <header style={{ backgroundImage: "url(" + teaser + ")" }}>
        <h1 className={common.headLine}>
          {title}
        </h1>
        <div id="station-map" className={styles.stationMap}>
          <nav className={styles.stationList}>
            <Station refs={refs.slice(0, 3)} colors={colors.slice(0, 3)} station={stations[0]} handleSwitchPage={(e, idx) => this.handleSwitchPage(e, 0 + idx)} />
            <Station refs={refs.slice(3, 6)} colors={colors.slice(3, 6)} station={stations[1]} handleSwitchPage={(e, idx) => this.handleSwitchPage(e, 3 + idx)} />
            <Station refs={refs.slice(6, 9)} colors={colors.slice(6, 9)} station={stations[2]} handleSwitchPage={(e, idx) => this.handleSwitchPage(e, 6 + idx)} />
          </nav>
        </div>
        <div className={styles.roadObject}>
          <div className={styles.busBoundingBox}>
            <div id="bus-object" className={styles.busObject} style={{ backgroundImage: "url(" + bus + "" }}></div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;